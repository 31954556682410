<template>
  <div class="hello">

    <br>
    <div style="width: 600px; margin: 0 auto;"><input type="text" class="form-control input-sm" placeholder="Введите например: Неврология" v-model="query" @keyup="fetchData()" />
    </div>
    <br>
        <div class="card-footer pb-0 pt-3">
        <jw-pagination :items="allData" @changePage="onChangePage" :labels="customLabels" :styles="customStyles"></jw-pagination>
    </div>
    <div v-for="row in pageOfItems" :key="row.id" style="width: 600px; " class='wrap-conf-sticker'>
      <div class='date-conf'><i class="fa fa-calendar-check-o"></i> {{ row.date }}</div>
      <div class="wrap-sticker-body">
        <div class="conf-sticker-name"><a :href="row.titlelink"><span>{{ row.title }}</span></a></div>
        <div class='place-conf'><i class="fa fa-map-marker"></i><span class='speciality-title'>Регистрация:</span><a :href="URLReplacer(row.link)">{{ row.link }}</a></div>
        <div class='org-conf'><span>{{ row.approval }}</span></div>
        <div class="wrap-conf-speciality"><span class='speciality-title'>Целевая аудитория:</span><br>
          <span>{{ row.specialization }}</span>
        </div>
      </div>
    </div>
    <div v-if="nodata"><p>No data</p></div>
        <div class="card-footer pb-0 pt-3">
            <jw-pagination :items="allData" @changePage="onChangePage" :labels="customLabels" :styles="customStyles"></jw-pagination>
        </div>
    </div>

</template>

<script>

const customStyles = {
        ul: {
            margin: 0,
            padding: 0,
            display: 'inline-block'
        },
        li: {
            listStyle: 'none',
            display: 'inline',
            textAlign: 'center'
        },
        a: {
            cursor: 'pointer',
            padding: '6px 12px',
            display: 'block',
            float: 'left'
        }
};

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>'
};

import axios from "axios";

export default {
  name: 'NMO-Plugin',
  data() {
    return {
      urlHtml:'',
      allData:'',
      query:'',
      nodata:false,
      pageOfItems: [],
      customStyles,
      customLabels,
    }
  },
  props: {
    msg: String
  },
  methods:{
    get(){
      alert('get method');
    },
    getNmo() {
      
      //var root = window.location.protocol + '//' + window.location.host + window.location.pathname;
      var root = window.location.protocol + '//' + window.location.host + '/';
      //var url = root + 'wp-content/plugins/webinar-plugin/wp-get-webinar.php?from=' + this.dateHtml;
      var url = root + 'php/wp-get-nmo.php';
      this.urlHtml = url;
      fetch(url)
      .then(response=>response.text())
      .then(data=>{ console.log(data);
        var jsonparse = JSON.parse(data);
        this.webinar = jsonparse.webinarName;
        this.sessionid = jsonparse.sessionid;

      });
    },
    fetchData() {
      axios.post('php/wp-show-nmo.php', {
        query:this.query
      }).then( response => {
        if(response.data.length > 0)
        {
          this.allData = response.data;
          this.nodata = false;
        }
        else
        {
          this.allData = '';
          this.nodata = true;
        }
      });
    },
    onChangePage(pageOfItems) {
        // update page of items
        this.pageOfItems = pageOfItems;
    },
    URLReplacer(str){
      var urlRegex = /(https?:\/\/[^\s]+)/g;
        //return str.replace(urlRegex, function(url) {
          //return '<a href="' + url + '">' + url + '</a>';
          //return url;
        //});
      return str.match(urlRegex);
    }
  },
  created:function(){
    this.fetchData();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.wrap-conf-sticker {
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid gainsboro;
  -webkit-border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  border-radius: 0px 0px 0px 0px;
  -moz-box-shadow: 3px 5px 8px 1px #EAEAEA;
  -webkit-box-shadow: 3px 5px 8px 1px #EAEAEA;
  box-shadow: 3px 5px 8px 1px #EAEAEA;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-map-marker {
  content: "\f041";
}
.fa-calendar-check-o{
  content: "\f274";
}
.conf-sticker-link {
  padding: 10px 0px;
  display: block;
}
.place-conf {
  margin-bottom: 10px;
}
.org-conf {
  margin-bottom: 10px;
  padding-left: 12px;
  font-style: italic;
}
</style>
